import { Grid, Paper } from '@achieve/sunbeam'
import { Typography } from 'components/Contentful'
import { Image } from 'components/Image'
import styles from './SiteWideBanner.module.scss'
import Section from 'components/Section'
import Collapse from '@mui/material/Collapse'
import { useEffect, useState } from 'react'

function SiteWideBanner({ title, message, handleCloseDialog }) {
  const [openBanner, setOpenBanner] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setOpenBanner(true)
    }, 3000)
  }, [])

  const closeDialog = () => {
    setOpenBanner(false)
    setTimeout(() => {
      handleCloseDialog && handleCloseDialog()
    }, 1000)
  }

  return (
    <Collapse orientation="vertical" in={openBanner}>
      <Section>
        <Paper className={styles['wide-banner-paper']} data-testid="wide-banner-paper-container">
          <div className={styles['wide-banner-close-icon']} onClick={() => closeDialog()}>
            <Image src="/close-icon.svg" alt="close-icon" layout="fill" priority={false} />
          </div>
          <Grid container direction="row" justifyContent="left" alignItems="left">
            <div className={styles['wide-grid-banner']}>
              <Grid className={styles['wide-banner-items']}>
                <Typography
                  component="div"
                  variant="displayS10"
                  fontWeight="bold"
                  className={styles['heading']}
                  content={title}
                />
                <Typography component="div" variant="displayXS30" content={message} />
              </Grid>
            </div>
          </Grid>
        </Paper>
      </Section>
    </Collapse>
  )
}

export { SiteWideBanner }
