import { Image } from 'components/Image'
import { rewriteAssetUrl } from 'utils/conversions/urls'

export function MediaImage({
  content,
  alt,
  width = '100%',
  height = '100%',
  layout = 'responsive',
  objectFit = 'contain',
  priority = false, // defaulting priority to false
  ...restProps
}) {
  const { file, title } = content?.fields || {}
  const assetUrl = rewriteAssetUrl(file?.url)
  if (layout === 'fill') {
    return (
      <Image
        src={assetUrl}
        alt={alt || title}
        layout={layout}
        objectFit={objectFit}
        priority={priority}
        {...restProps}
      />
    )
  }
  return (
    <Image
      src={assetUrl}
      alt={alt || title}
      width={width}
      height={height}
      layout={layout}
      objectFit={objectFit}
      priority={priority}
      {...restProps}
    />
  )
}
