import { PRD_ENV } from 'constants/environments'
import { createClient } from 'contentful'

// client will persist
let client

// Extracted clientParamsObj to be more testable
const getClientParamsObj = (queryParameters = {}) => {
  const CONTENTFUL_ENVIRONMENT =
    process.env?.NEXT_PUBLIC_APP_ENV !== PRD_ENV && queryParameters.CONTENTFUL_ENVIRONMENT
      ? queryParameters.CONTENTFUL_ENVIRONMENT
      : process.env.CONTENTFUL_ENVIRONMENT
  // Because env vars are strings
  const previewEnabled = process.env.CONTENTFUL_PREVIEW_ENABLED === 'true'
  const clientParamsObj = {
    // Yes we could have one access token, but two makes more sense,
    // because not having to change it when developing and to be more explicit.
    accessToken: previewEnabled
      ? process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN
      : process.env.CONTENTFUL_ACCESS_TOKEN,
    environment: CONTENTFUL_ENVIRONMENT,
    resolveLinks: true,
    space: process.env.CONTENTFUL_SPACE_ID,
  }
  if (previewEnabled) {
    clientParamsObj.host = process.env.CONTENTFUL_PREVIEW_HOST
  }
  return clientParamsObj
}

/**
 * Creates the Contentful client. Call once
 * @returns {ContentfulClientApi} object of methods
 */
const getContentfulClient = (queryParameters) => {
  // Too bad we can't import types to do a proper validation of this
  if (client instanceof Object) {
    return client
  }
  try {
    client = createClient(getClientParamsObj(queryParameters))
    return client
  } catch (e) {
    console.error(
      `Contentful service: Unable to init client for space: ${process.env.CONTENTFUL_SPACE_ID} environment: ${process.env.CONTENTFUL_ENVIRONMENT}`
    )
    // We should rethrow because this gets used as so:
    // getContentfulClient().getEntries()
    // and if we don't return an object it's going to fail with:
    // Cannot read properties of undefined
    // It all stems from findAndFetchPageContent() where it handles the error and returns EMPTY_CONTENT
    throw e
  }
}

export { getContentfulClient, getClientParamsObj }
export default getContentfulClient
