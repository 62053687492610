export const FEATURE_TOGGLES = {
  ACX_WEB_ENABLE_ALCHEMER_SURVEY: 'acx-web-enable-alchemer-survey',
  ACX_WEB_ENABLE_BLOG: 'acx-web-enable-blog',
  ACX_WEB_ENABLE_CONSUMER_NAME: 'acx-web-enable-consumer-blog',
  ACX_WEB_ENABLE_LEARN: 'acx-web-enable-learn',
  ACX_WEB_ENABLE_NAV_TITLES: 'acx-web-enable-nav-titles',
  ACX_WEB_ENABLE_UTM_PARSE: 'acx-web-fix-duplicate-query-strings',
  ACX_WEB_ENABLE_CONTACT_US: 'acx-web-enable-contact-us',
  ACX_WEB_ENABLE_FAQ_RESOLVE_DEBT: 'acx-web-enable-faq-resolve-debt',
  ACX_WEB_ENABLE_FAQ_PERSONAL_LOAN: 'acx-web-enable-faq-personal-loan',
  ACX_WEB_ENABLE_FAQ_HOME_EQUITY_LOAN: 'acx-web-enable-faq-home-equity-loan',
  ACX_WEB_ENABLE_SMS_DISCLOSURES: 'acx-web-enable-sms-disclosures',
  ACX_WEB_ENABLE_SEO: 'acx-web-enable-seo-updates-2022-11-07',
  ACX_WEB_ENABLE_SITE_WIDE_BANNER: 'acx-web-enable-site-wide-banner',
  ACX_WEB_ENABLE_MOLO: 'acx-web-enable-molo',
  ACX_WEB_ENABLE_EMPLOYEE_STORIES: 'acx-web-enable-employee-stories',
  ACX_WEB_ENABLE_PARTNERS: 'acx-web-enable-partners',
  ACX_WEB_ENABLE_GOOD: 'acx-web-enable-good',
  ACX_WEB_ENABLE_ARTICLE_HOOK: 'acx-web-enable-article-hook',
  ACX_WEB_ENABLE_DEBT_INCOME_RATIO_CALCULATOR: 'acx-web-enable-debt-income-ratio-calculator',
  ACX_WEB_ENABLE_DEBT_CONSOLIDATION: 'acx-web-enable-debt-consolidation',
  ACX_WEB_ENABLE_AR_HOW: 'acx-web-enable-ar-how',
  ACX_WEB_ENABLE_AR_WHO: 'acx-web-enable-ar-who',
  ACX_WEB_ENABLE_AR_WHY: 'acx-web-enable-ar-why',
  ACX_WEB_ENABLE_AR_DISCLAIMERS: 'acx-web-enable-ar-disclaimers',
}

export const EVENT_TYPES = {
  START: 'start',
  CONVERSION: 'conversion',
  TRACK: 'track',
}

// values should be all lower case
export const VARIATIONS = {
  CONTROL: 'control',
  VARIATION_A: 'variation_a',
  VARIATION_B: 'variation_b',
  VARIATION_C: 'variation_c',
  UNASSIGNED: 'unassigned',
  OFF: 'off',
}

export default FEATURE_TOGGLES
