import { Button, Typography } from '@achieve/sunbeam'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { GET_STARTED_ROUTE } from 'constants/navigation'
import { EVENT_LIST } from 'utils/analytics/trackingLibrary/eventList'

export default function HeaderCta({
  className = '',
  'data-testid': dataTestId,
  linkUrl,
  linkText,
  ...buttonProps
}) {
  return (
    <AchieveLink
      className={className}
      href={linkUrl ? linkUrl : GET_STARTED_ROUTE}
      data-testid={dataTestId}
      track={{
        list_name: EVENT_LIST.HEADER_NAV_MAIN_CTA_BUTTON,
      }}
    >
      <Button color="primary" variant="contained" {...buttonProps}>
        <Typography variant="displayXS30" fontWeight="bold">
          {linkText ? linkText : `Get Started`}
        </Typography>
      </Button>
    </AchieveLink>
  )
}
