import { Typography } from 'components/Contentful'
import { Grid } from '@achieve/sunbeam'
import styles from './BlockQuote.module.scss'

import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import React from 'react'

const BlockQuote = ({ text }) => {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.md)
  return (
    <Grid className={styles['container-grid']}>
      {text &&
        text.map((textContent, idx) => (
          <React.Fragment key={idx}>
            <Typography
              className={styles['text']}
              fontWeight={isMobile ? 'regular' : 'medium'}
              variant={'displayS20'}
              content={textContent.content[0].value}
            />
          </React.Fragment>
        ))}
    </Grid>
  )
}

export { BlockQuote }
