import { createContext } from 'react'

const FeatureFlagContext = createContext({})

const FeatureFlagProvider = ({ children, featureToggleObject }) => {
  return (
    <FeatureFlagContext.Provider value={{ featureFlags: featureToggleObject }}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export { FeatureFlagProvider, FeatureFlagContext }
