import { BREAKPOINTS } from 'utils/mui'

export const videoMap = {
  [BREAKPOINTS.xxl]: {
    width: 1007,
    height: 567,
  },
  [BREAKPOINTS.xl]: {
    width: 960,
    height: 540,
  },
  [BREAKPOINTS.lg]: {
    width: 704,
    height: 396,
  },
  [BREAKPOINTS.md]: {
    width: 704,
    height: 396,
  },
  [BREAKPOINTS.sm]: {
    width: 447,
    height: 320,
  },
  [BREAKPOINTS.xs]: {
    width: 310,
    height: 174,
  },
  [BREAKPOINTS.xxs]: {
    width: 310,
    height: 174,
  },
}
