import { useRouter } from 'next/router'

const useIsBlog = () => {
  const router = useRouter()
  const route = router.asPath
    ? router.asPath.split('/')[2] === 'blog'
      ? 'blog'
      : router.asPath.split('/')[1]
    : ''

  return route
}

export { useIsBlog }
