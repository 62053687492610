import { useContext } from 'react'
import { AnalyticsContext } from 'providers/AnalyticsProvider'

const useAnalyticsTrackEvent = () => {
  const { dispatch } = useContext(AnalyticsContext)

  const analyticsTrackEvent = (payload) => {
    if (!payload || payload.constructor.name !== 'Object') {
      console.warn('Analytics data must be an Object')
      return
    }
    return dispatch({
      type: 'ADD_EVENT_TO_QUEUE',
      payload,
    })
  }
  return { analyticsTrackEvent }
}

export { useAnalyticsTrackEvent }
