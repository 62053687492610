export const BLOG_ROUTE = '/about/blog'
export const CAREERS_ROUTE = 'https://careers.achieve.com'
export const CONSUMER_NAME_ROUTE = '/dollars-sense'
export const GET_STARTED_ROUTE = '/get-started?brand=achieve&section=achieve_web'
export const GET_STARTED_ROUTE_HL = '/get-started?brand=achieve&section=achieve_hl'
export const LEARN_ROUTE = '/learn'
export const LICENSE_ROUTE = '/licenses'
export const MEMBER_STORIES_ROUTE = '/about/member-stories'
export const PRESS_ROUTE = '/about/press'
export const SIGN_IN_ROUTE = '/signin'
export const ROUTE_EXCEPT_NOFOLLOW = ['achieve.com', 'dev.ffngcp.com', 'stg.ffngcp.com']
export const ROUTE_ASSET_EXCEPT = 'assets.ctfassets.net'
export const CONTACT_US_ROUTE = '/about/contact-us'
export const EMPLOYEE_STORIES_ROUTE = '/about/employee-stories'
export const MOLO_ROUTE = '/molo-app'
export const PARTNERS_ROUTE = '/about/partners'
export const GOOD_ROUTE = '/good-app'
export const DEBT_CONSOLIDATION = '/debt-consolidation'
export const AR_HOW_ROUTE = '/resolve-debt/how-achieve-resolution-works'
export const AR_WHO_ROUTE = '/resolve-debt/who-achieve-resolution-helps'
export const AR_WHY_ROUTE = '/resolve-debt/why-choose-achieve-resolution'
export const AR_DISCLAIMERS_ROUTE = '/resolve-debt/disclaimers'
export const DEBT_INCOME_RATIO_ROUTE = '/tools/debt-income-ratio-calculator'
