import { useConfig } from 'providers/AnalyticsProvider'
import { handleTrackAndReactEvent } from 'utils/analytics'
import { useAnalyticsTrackEvent } from 'hooks/useAnalyticsTrackEvent'
import styles from './TrackingWrapper.module.scss'
import { useEventScope } from 'hooks/useEventScope'
import { updateTrackWithListedEvent } from 'utils/analytics/trackingLibrary/updateTrackEventWithListedEvent'
import { useRouter } from 'next/router'

function TrackingWrapper({ track, onClick, withNextLink, children, ...props }) {
  const { config } = useConfig()
  const { analyticsTrackEvent } = useAnalyticsTrackEvent()
  const specificity = useEventScope()
  const router = useRouter()
  let url = router.asPath

  const localClickHandler = (event, track, onClick, withNextLink) => {
    const getTrackingObject = (track) => {
      track = updateTrackWithListedEvent(track, specificity)

      if (track.click_type) {
        const trackData = {
          ...track,
          click_text: track.click_text
            ? track.click_text
            : `${config.NEXT_PUBLIC_ANALYTICS_APP_NAME} | ${track.click_id}`,
        }

        if (track?.href) {
          if (track?.href.substring(0, 1) == '#') {
            trackData['click_url'] = url
          } else {
            trackData['click_url'] = `${track.href}`
          }
        }
        return trackData
      } else {
        return track
      }
    }
    if (withNextLink) {
      event.stopPropagation()
      event.preventDefault()
    }

    analyticsTrackEvent(handleTrackAndReactEvent(event, getTrackingObject(track), onClick))
  }

  return (
    <span
      className={styles['wrapper']}
      {...props}
      onClick={(event) => {
        localClickHandler(event, track, onClick, withNextLink)
      }}
    >
      <> {children}</>
    </span>
  )
}

export { TrackingWrapper }
