import { useMemo } from 'react'
import { contentMap } from 'utils/contentful'

const useMemoizedContentGetter = (content, fieldNames) => {
  return useMemo(
    () => contentMap(content?.fields?.sectionContents, fieldNames),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [content, ...fieldNames]
  )
}

const useInlineMemoizedContentGetter = (content, fieldNames) => {
  return useMemo(
    () => contentMap(content?.fields, fieldNames),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [content, ...fieldNames]
  )
}

export { useMemoizedContentGetter, useInlineMemoizedContentGetter }
