const TRACK_EVENTS = {
  INTERNAL_LINK_CLICK: 'internal_campaign_click',
  PAGE_NAV_CLICK: 'page_nav_click',
  BUTTON_CLICK: 'button_click',
  LINK_CLICK: 'link_click',
  UI_CLICK: 'ui_click',
}

/* Button Click */
const BUTTON_CLICK = {
  click_type: 'Button Click',
  event_action: TRACK_EVENTS.BUTTON_CLICK,
  event_type: TRACK_EVENTS.BUTTON_CLICK,
  track_event: TRACK_EVENTS.BUTTON_CLICK,
}

/* Link Click */
const LINK_CLICK = {
  click_type: 'Link Click',
  event_action: TRACK_EVENTS.LINK_CLICK,
  event_type: TRACK_EVENTS.LINK_CLICK,
  track_event: TRACK_EVENTS.LINK_CLICK,
}

/* UI Click */
const UI_CLICK = {
  click_type: 'UI Click',
  event_action: TRACK_EVENTS.UI_CLICK,
  event_type: TRACK_EVENTS.UI_CLICK,
  track_event: TRACK_EVENTS.UI_CLICK,
}

// TODO: Move all event names  and scopes to constants

// Scopes

// Events
const HEADER_NAV_LINK_CLICK = 'Header Nav Link Click'
const HEADER_NAV_MAIN_CTA_BUTTON = 'Header Nav Main CTA Button'
const VIDEO_DIALOG_CTA_BUTTON_CLICK = 'Video Dialog CTA Button Click'
const VIDEO_DIALOG_CTA_LINK_CLICK = 'Video Dialog CTA Link Click'
const VIDEO_PLAYER_PLAY_INITIATED = 'Video Player Play Initiated'

const HOME_HERO_CTA_CLICK = 'Home Hero CTA click'

const EVENT_LIST = {
  HEADER_NAV_LINK_CLICK,
  HEADER_NAV_MAIN_CTA_BUTTON,
  VIDEO_DIALOG_CTA_BUTTON_CLICK,
  VIDEO_DIALOG_CTA_LINK_CLICK,
  VIDEO_PLAYER_PLAY_INITIATED,
}

let events = {
  [HEADER_NAV_LINK_CLICK]: {
    ...LINK_CLICK,
    nav_link_section: 'Mega Menu',
    track_event: 'mega_menu',
  },
  [HEADER_NAV_MAIN_CTA_BUTTON]: {
    ...BUTTON_CLICK,
    list_name: HEADER_NAV_MAIN_CTA_BUTTON,
    nav_link_section: 'Header',
    click_id: 'Get Started',
    track_event: 'global_header',
    click_text: `Achieve-Web | Get Started`,
  },
  'Header Nav Sign in Link': {
    ...LINK_CLICK,
    list_name: 'Header Nav Sign in Link',
    click_id: 'Sign in',
    nav_link_section: 'Header',
    track_event: 'global_header',
  },
  'Header Logo': {
    ...LINK_CLICK,
    list_name: 'Header Logo',
    nav_link_section: 'Header logo',
    track_event: 'global_header',
  },
  'Footer Nav Link Click': {
    ...LINK_CLICK,
    nav_link_section: 'Footer Click',
    track_event: 'global_footer',
  },
  'Footer Logo': {
    ...LINK_CLICK,
    nav_link_section: 'Footer',
    click_id: '2022_Achieve_Logomark_RGB',
    track_event: 'global_footer',
  },
  [HOME_HERO_CTA_CLICK]: {
    ...BUTTON_CLICK,
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Home Madlibs submit': {
    ...BUTTON_CLICK,
    nav_link_section: 'Mad Lib selection',
    click_position: '0',
    click_id: 'home_mad_lib',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Home Madlibs results': {
    nav_link_section: 'Recommended Cards',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Home Madlibs reset': {
    nav_link_section: 'Recommended Cards',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Product Cards Download': {
    ...LINK_CLICK,
    nav_link_section: 'Recommended Cards',
    click_position: '0',
    click_id: 'product cards',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Product Cards Button': {
    ...BUTTON_CLICK,
    nav_link_section: 'Recommended Cards',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Personalized Value Props CTA': {
    ...BUTTON_CLICK,
    nav_link_section: 'Value Props',
    click_position: '0',
    click_id: 'Get Started',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Home Testimonials Carousel': {
    ...UI_CLICK,
    click_id: 'Right Carousel -or- Left Carousel',
    nav_link_section: 'Testimonials',
  },
  'Home More Resources': {
    nav_link_section: 'Recommended Cards',
    track_event: TRACK_EVENTS.PAGE_NAV_CLICK,
  },
  'Home Closer': {
    nav_link_section: 'Closer Statement',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Product Page Hero Form CTA': {
    nav_link_section: 'Hero Form',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Product Page Values CTA': {
    nav_link_section: 'Value Props',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Product Page Testimonials Carousel': {
    ...UI_CLICK,
    click_id: 'Right Carousel -or- Left Carousel',
    nav_link_section: 'Testimonials',
    page_path: '/',
  },
  'Product Page FAQs': {
    ...LINK_CLICK,
    click_position: '0',
    click_id: 'faq button',
    nav_link_section: 'FAQs',
  },
  'Product Page FAQ CTA': {
    ...LINK_CLICK,
    nav_link_section: 'Closer Statement',
    click_position: '0',
    click_id: 'faq button',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Product Page Product Cards': {
    nav_link_section: 'Hero Form',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Product Page More Resources': {
    ...LINK_CLICK,
    nav_link_section: 'Recommended Cards',
    click_position: '0',
    click_id: 'recommended cards',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Product Page Closer': {
    ...BUTTON_CLICK,
    nav_link_section: 'Closer Statement',
    click_position: '0',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Member Stories Closer Statement': {
    ...LINK_CLICK,
    nav_link_section: 'Closer Statement',
    click_position: '0',
    click_id: 'Get Started',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Listing Page Filter Results': {
    ...BUTTON_CLICK,
    click_id: 'Filter Results',
    click_position: '0',
    nav_link_section: 'Content Filters',
  },
  'Listing Page Category Filter': {
    ...UI_CLICK,
    click_position: '0',
    nav_link_section: 'Content Filters',
  },
  'Listing Page Clear Filter': {
    ...UI_CLICK,
    application_version: 'Prod',
    click_id: 'Clear filters',
    click_position: '0',
    click_text: 'Achieve-Web | Clear filters',
    nav_link_section: 'Content Filters',
  },
  'Card Media Event': {
    ...LINK_CLICK,
    nav_link_section: 'Card Media',
    click_position: '0',
    click_id: 'card_media',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Hero Carousel Event': {
    ...UI_CLICK,
    nav_link_section: 'Hero Carousel',
    click_position: '0',
    click_id: 'buttonCta',
    hero_card_text: 'buttonCta',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Hero Form': {
    ...BUTTON_CLICK,
    nav_link_section: 'Hero Form',
    click_position: '0',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Mad Lib Result': {
    ...LINK_CLICK,
    mad_lib_card_recomMended: false,
    nav_link_section: 'Recommended Cards',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Sign In Click': {
    ...LINK_CLICK,
    list_name: 'Header Nav Sign in Link',
    click_id: 'Sign in',
    nav_link_section: 'Header',
    track_event: 'global_header',
  },
  'About Closer Statement': {
    ...LINK_CLICK,
    nav_link_section: 'About Closer Statement',
    click_position: '0',
    click_id: 'about closer statement button',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Card Pres Read More': {
    ...LINK_CLICK,
    nav_link_section: 'Content Grid',
    track_event: TRACK_EVENTS.PAGE_NAV_CLICK,
    click_text: 'Read more',
  },
  'Pagination Event': {
    ...UI_CLICK,
    click_id: 'Achieve Web | Pagination',
    click_text: 'Pagination',
    nav_link_section: 'Pagination',
    click_position: 'Pagination',
  },
  'Card Article': {
    ...LINK_CLICK,
    nav_link_section: 'Content Grid',
    track_event: TRACK_EVENTS.PAGE_NAV_CLICK,
  },
  'Recommended Card Link': {
    ...BUTTON_CLICK,
    nav_link_section: 'Product Cards',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'Carousel Nav': {
    ...UI_CLICK,
    nav_link_section: 'Testimonials',
  },
  Breadcrumb: {
    ...LINK_CLICK,
    event_name: 'Achieve Web | ',
    click_position: '0',
    nav_link_section: 'Breadcrumb',
    track_event: TRACK_EVENTS.PAGE_NAV_CLICK,
  },
  'Right Rail Read More': {
    ...LINK_CLICK,
    click_position: 'Read the article',
    click_text: `Achieve-Web | Read the article`,
    nav_link_section: 'Suggested Content - Right Rail',
    track_event: TRACK_EVENTS.PAGE_NAV_CLICK,
  },
  'Right Rail Title': {
    ...LINK_CLICK,
    nav_link_section: 'Suggested Content - Right Rail',
    track_event: TRACK_EVENTS.PAGE_NAV_CLICK,
  },
  Categories: {
    ...LINK_CLICK,
    nav_link_section: 'Categories',
    track_event: TRACK_EVENTS.PAGE_NAV_CLICK,
  },
  'Inline Link': {
    ...BUTTON_CLICK,
    click_position: '0',
    nav_link_section: 'CTA Card - In-Content',
    track_event: TRACK_EVENTS.PAGE_NAV_CLICK,
  },
  'CTA CARD': {
    ...BUTTON_CLICK,
    click_position: '0',
    track_event: TRACK_EVENTS.PAGE_NAV_CLICK,
  },
  'FAQ ACCORDION EXPANDED': {
    ...UI_CLICK,
    nav_link_section: 'FAQs',
  },
  'FAQ ACCORDION LESS/MORE': {
    ...UI_CLICK,
    click_position: '0',
    nav_link_section: 'FAQs',
  },
  'FAQ SEE ALL': {
    ...LINK_CLICK,
    click_id: 'See all FAQs',
    click_position: 0,
    click_text: 'See all FAQs',
    nav_link_section: 'FAQs',
    track_event: TRACK_EVENTS.PAGE_NAV_CLICK,
  },
  'PRODUCT FORM': {
    ...BUTTON_CLICK,
    nav_link_section: 'Product Form',
  },
  'PRODUCT FORM CTA': {
    ...BUTTON_CLICK,
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
    nav_link_section: 'Product Form',
    click_position: '0',
  },
  'PRODUCT DETAILS': {
    ...BUTTON_CLICK,
    nav_link_section: 'Product Details',
  },
  'ARTICLE LINK': {
    ...LINK_CLICK,
    nav_link_section: `Article`,
  },
  'DTI SUBMIT': {
    nav_link_section: 'Tool Form',
  },
  'DTI CTA CLICK': {
    nav_link_section: 'Tool Next Steps',
    track_event: TRACK_EVENTS.INTERNAL_LINK_CLICK,
  },
  'DTI API RESPONSE': {
    event_action: 'app_event',
  },
  [VIDEO_DIALOG_CTA_BUTTON_CLICK]: {
    ...BUTTON_CLICK,
    click_id: VIDEO_DIALOG_CTA_BUTTON_CLICK,
  },
  [VIDEO_DIALOG_CTA_LINK_CLICK]: {
    ...LINK_CLICK,
    click_id: VIDEO_DIALOG_CTA_LINK_CLICK,
  },
  [VIDEO_PLAYER_PLAY_INITIATED]: {
    ...UI_CLICK,
    click_id: VIDEO_PLAYER_PLAY_INITIATED,
  },
  'Recommended Cards Carousel Link': {
    ...LINK_CLICK,
    nav_link_section: 'Recommended Cards',
  },
  'AR STAT BUTTON': {
    ...BUTTON_CLICK,
    nav_link_section: 'Social & Outcome Stats',
  },
  'Post Social Share': {
    click_type: 'Button Click',
    nav_link_section: 'Social Sharing',
    track_event: 'social_share',
    event_action: TRACK_EVENTS.BUTTON_CLICK,
  },
}

export { events, EVENT_LIST }
