export const som = `{
 "@context": "https://schema.org",
 "@type": "Organization",
 "@id": "https://www.achieve.com/#organization",
 "name": "Achieve",
 "url": "https://www.achieve.com",

 "logo": {
   "@type": "ImageObject",
   "url": "//images.ctfassets.net/80jeq2aeq4l1/6buj5UWLThOWWXMbxEuIDw/e7bf107b337003b610eeb4fea631e9ec/achievelogo_160x50__1_.png",
   "width": "160",
   "height": "50"
 },
 "contactPoint": {
  "@type": "ContactPoint",
  "contactType": "main contact number",
  "telephone": "+18009200045",
  "url": "https://www.achieve.com/contact-us"
}, 
  "sameAs": [
    "https://www.facebook.com/achievecom/",
    "https://www.instagram.com/achievecom/",
    "https://www.linkedin.com/company/achievecareers/",
    "https://www.youtube.com/achievecom",
    "https://twitter.com/achievecom"
 ]
}`
