/**
 * Takes text and return sulugified version
 * @param {String} slug
 * @returns {String} formatted slug string
 */
function getSlug(slug = '') {
  return slug
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

function isExternal(url, currentURL) {
  try {
    const CURRENT_URL = new URL(currentURL)
    const URLObject = new URL(url)
    return URLObject.host != CURRENT_URL.host
  } catch (err) {
    return false
  }
}

/**
 * Replace domain name in the given asset URL to the appropriate
 * domain name for the respective asset types
 *
 * @param {string} url - The original asset URL
 * @returns {string} The modified asset URL
 */
const rewriteAssetUrl = (url) => {
  if (typeof url !== 'string' || !url) return ''
  return url
    .replace('//images.ctfassets.net', '/assets/images')
    .replace('//assets.ctfassets.net', '/assets/files')
}

export { getSlug, isExternal, rewriteAssetUrl }
