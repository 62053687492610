import { GET_STARTED_ROUTE } from 'constants/navigation'

/**
 * Adds UTM parameters to a link url if it is a Get Started link.
 *
 * @param {string} memoHref - The memo href to add UTM parameters to.
 * @returns {string} The memo href with added UTM parameters.
 */
const getProductAndAddUtmToGetStarted = (memoHref) => {
  // Check if the memo href is a get started link.
  const isGetStarted =
    memoHref?.replace('/', '').substring(0, 11) === GET_STARTED_ROUTE.split('?')[0].replace('/', '')

  // If the memo href is a get started link, add UTM parameters.
  if (isGetStarted) {
    // Add utm_medium parameter if it does not exist.
    if (memoHref.indexOf('utm_medium') === -1) {
      memoHref = `${memoHref}&utm_medium=organic`
    }

    // Add utm_media parameter if it does not exist or if utm_term also exists.
    if (memoHref.indexOf('utm_media') === -1 && memoHref.indexOf('utm_term') === -1) {
      memoHref = `${memoHref}&utm_media=organic`
    }

    // Add utm_source parameter if it does not exist
    if (memoHref.indexOf('utm_source') === -1) {
      let product
      product = memoHref
        ?.split('?')[1]
        ?.split('&')
        ?.filter((qp) => qp.split('=')[0] == 'section')[0]
        .split('=')[1]
        .split('_')[1]
      memoHref = `${memoHref}&utm_source=achieve_organic_${product}`
    }
  }

  return memoHref
}

export { getProductAndAddUtmToGetStarted }
