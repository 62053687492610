import useMediaQuery from 'hooks/useMediaQuery'
import useTheme from 'hooks/useTheme'
import useWindowSize from 'hooks/useWindowSize'

/**
@see {@link https://bitbucket.org/billsdotcom_bills/sunbeam/src/main/src/styles/tokens/breakpoints/breakpoints.json | Sunbeam Breakpoints}
*/
const BREAKPOINTS = {
  xxs: 'xxs',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg', // For launch, desktop designs begin at the `lg` breakpoint
  xl: 'xl',
  xxl: 'xxl',
}

const maxBreakpoints = {
  xxs: 0,
  xs: 320,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
}

/**
 * @deprecated 7/8/22 - this is staying around to avoid breaking changes. `useViewportLargerThan`
 * and `useViewportSmallerThan` are preferred.
 */
const useIsMobile = (breakpoint = BREAKPOINTS.lg) => {
  return useMediaQuery(useTheme().breakpoints.down(breakpoint))
}

/**
 * Check to see if a viewport is larger than a given breakpoint
 * @param {string} [breakpoint='xxs'] - xxs, xs, sm, md, lg, xl, xxl
 * @returns boolean
 */
// const useViewportLargerThan = (breakpoint = BREAKPOINTS.xxs) => {
//   return useMediaQuery(useTheme().breakpoints.up(breakpoint))
// }

const useViewportLargerThan = (breakpoint = BREAKPOINTS.xxs) => {
  const { width } = useWindowSize()
  return width && width > maxBreakpoints[breakpoint]
}

/**
 * Check to see if a viewport is smaller than a given breakpoint
 * @param {string} [breakpoint='xxl'] - xxs, xs, sm, md, lg, xl, xxl
 * @returns boolean
 */
// const useViewportSmallerThan = (breakpoint = BREAKPOINTS.xxl) => {
//   return useMediaQuery(useTheme().breakpoints.down(breakpoint))
// }
const useViewportSmallerThan = (breakpoint = BREAKPOINTS.xxl) => {
  const { width } = useWindowSize()
  return width && width < maxBreakpoints[breakpoint]
}

export { useIsMobile, useViewportLargerThan, useViewportSmallerThan, BREAKPOINTS }
