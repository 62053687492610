import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { FFAM } from 'constants/ffam'
import { useInlineMemoizedContentGetter } from 'utils/contentful'
import { SiteWideBanner } from 'components/SiteWideBanner'

function FFAMBanner({ handleCloseDialog, isFFAMReferrer, ffamReferrerContent }) {
  const { isReady } = useRouter()
  const [specialMessageCookieCount, setSpecialMessageCookieCount] = useState(0)

  const { title, message } = useInlineMemoizedContentGetter(ffamReferrerContent, [
    'title',
    'message',
  ])

  const checkCookie = () => {
    // fetch the special message cookie for FFAM
    const cookieValue = Cookies.get(FFAM.SPECIAL_MESSAGE_COOKIE_NAME)
    // If the cookie is not set set the value to 1
    if (!cookieValue) {
      Cookies.set(FFAM.SPECIAL_MESSAGE_COOKIE_NAME, 1, { expires: 60 })
      setSpecialMessageCookieCount(1)
    } else {
      // Increment the value by 1
      const cookieValueIncrement = parseInt(cookieValue) + 1
      Cookies.set(FFAM.SPECIAL_MESSAGE_COOKIE_NAME, cookieValueIncrement, { expires: 60 })
      setSpecialMessageCookieCount(cookieValueIncrement)
    }
  }

  useEffect(() => {
    checkCookie()
  }, [])

  if (specialMessageCookieCount > 1) return null
  if (!isReady) return null
  if (!isFFAMReferrer) return null

  return (
    <SiteWideBanner
      title={title}
      message={message}
      handleCloseDialog={() => handleCloseDialog && handleCloseDialog()}
    />
  )
}

export { FFAMBanner }
