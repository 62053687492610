import Script from 'next/script'

function Alchemer() {
  return (
    <Script
      id="alchemer-base"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `(function(d,e,j,h,f,c,b){d.SurveyGizmoBeacon=f;d[f]=d[f]||function(){(d[f].q=d[f].q||[]).push(arguments)};c=e.createElement(j),b=e.getElementsByTagName(j)[0];c.async=1;c.src=h;b.parentNode.insertBefore(c,b)})(window,document,'script','//d2bnxibecyz4h5.cloudfront.net/runtimejs/intercept/intercept.js','sg_beacon');
sg_beacon('init','NzMyMTk0LTUyNmY0YTJlZjJmYzdmZjM3Y2FjMDM2OTg4M2IwMjE4ZTE4ZTI2NzQxZWIwN2M2N2Jm');`,
      }}
    />
  )
}

export { Alchemer }
