import { ANALYTICS } from 'constants/analytics'
import { useEffect, useContext } from 'react'
import { useRouter } from 'next/router'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import Cookies from 'js-cookie'
import { setSessionId } from 'utils/analytics/session'

const baseObject = {
  event_origin: 'client',
}

const kebabCaseToPascalCase = (str) => {
  return str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const getPagePath = (pathname) => {
  if (pathname === '/') return pathname
  if (pathname === undefined) return '/'
  // remove proceeding slash and ensure pathname does not include query string
  return pathname.substring(1).split('?')[0]
}

const waitForTealium = (fn) => {
  // ACX-145: Delay page track so Tealium data is present
  // It would be better to queue these events in the AnalyticsProvider,
  // but we need a way to watch window.utag.data.tealum_session_id
  // Yes we could queue until the next event or page render but what if there isn't one
  let delay = 2500
  if (window?.utag?.data?.tealium_session_id) {
    delay = 0
  }
  const timer = setTimeout(() => {
    fn()
  }, delay)
  return () => clearTimeout(timer)
}

const constructTrackingObject = (
  config = {},
  { pagePath, referer, urlParams, host, fullPagePath, pageRealPath } = {}
) => {
  let pagePathPublic = pageRealPath
    ? pageRealPath === pagePath
      ? pagePath
      : pageRealPath
    : pagePath

  if (pagePathPublic?.includes('/[')) {
    pagePathPublic = pagePath
  }

  const page_name = `${config.NEXT_PUBLIC_ANALYTICS_APP_NAME} | ${
    pagePath === '/'
      ? config.NEXT_PUBLIC_ANALYTICS_APP_TITLE
      : kebabCaseToPascalCase(pagePathPublic)
  }`
  const page_url = `https://${host}/${pagePath !== '/' ? pagePath : ''}`
  return {
    page_name,
    event_name: `${page_name} - Page Viewed`,
    browser_id: Cookies.get(ANALYTICS.BROWSER_ID_KEY_NAME),
    session_id: sessionStorage.getItem(ANALYTICS.SESSION_ID_KEY_NAME),
    application_version: config.NEXT_PUBLIC_ANALYTICS_ENV,
    referring_url: referer || page_url,
    url_params: urlParams,
    page_title:
      pagePath === '/'
        ? config.NEXT_PUBLIC_ANALYTICS_APP_TITLE
        : kebabCaseToPascalCase(pagePathPublic),
    page_path: `${config.NEXT_PUBLIC_ANALYTICS_APP_NAME} | ${pagePath}`,
    page_url: page_url,
    full_page_url: `https://${host}${fullPagePath}`,
  }
}

/**
 * ACX-194
 *
 * Handle track and react events
 * This allows you to have a track prop and/or onClick prop
 * If you have both a track and onClick prop,
 * they will be called in parallel
 *
 * If track is a boolean then the default track behavior will be used
 * If track is a function then the function will be called with the event
 * If track is an object then the object will override event and return,
 * and override or add additional properties
 * @param {SyntheticBaseEvent} e - click event
 * @param {*} track - track prop, boolean, function, or object
 * @param {Object} reactEvent - any react event such as onClick
 */
const handleTrackAndReactEvent = (e, track, reactEvent) => {
  if (typeof reactEvent === 'function') {
    reactEvent(e)
  }

  if (typeof track === 'boolean' && track === true) {
    return e
  } else if (typeof track === 'function') {
    return track(e)
  } else if (typeof track === 'object') {
    // Nov 30 2022
    // We can come back to this but this was the root of a circular json error
    // Reproduce by click one nav item then another.
    // For instance, Change Password > Cancel > Achieve Logo
    //return { e, ...track }
    return track
  }
}

const trackEvent = (
  analyticsInstance,
  config,
  {
    ids,
    pagePath,
    fullPagePath,
    urlParams,
    referer,
    profile,
    experiments,
    host,
    event_type = 'click',
    auth0_user_id,
    auth0_email,
    profile_id,
    ...application_data
  }
) => {
  const {
    page_name,
    event_name,
    browser_id,
    session_id,
    application_version,
    referring_url,
    url_params,
    page_title,
    page_path,
    page_url,
    full_page_url,
  } = constructTrackingObject(config, {
    pagePath,
    referer,
    urlParams,
    host,
    fullPagePath,
  })

  let event_action_override = null
  let event_name_override = null

  if (application_data.event_action) {
    event_action_override = application_data.event_action
    event_type = event_action_override
    delete application_data.event_action
  }

  if (application_data.event_name) {
    event_name_override = `${application_data.event_name} ${event_action_override}`
    delete application_data.event_name
  }

  if (application_data.href) {
    delete application_data.href
  }

  analyticsInstance.track(event_type, {
    ...baseObject,
    event_action: event_action_override ? event_action_override : event_type,
    page_name,
    event_name: event_name_override ? event_name_override : event_name,
    application_data: {
      ...application_data,
      application_version,
      url_params,
      page_title,
      page_path,
      page_url,
      full_page_url,
      referring_url,
    },
    ids: {
      ...ids,
      browser_id,
      session_id,
      auth0_user_id,
      auth0_email,
      profile_id,
    },
    profile,
    experiments,
  })
}

const trackPage = (
  analyticsInstance,
  config,
  {
    ids,
    pagePath,
    fullPagePath,
    urlParams,
    referer,
    profile,
    experiments,
    host,
    auth0_user_id,
    auth0_email,
    profile_id,
    pageRealPath,
    ...application_data
  }
) => {
  const {
    page_name,
    event_name,
    browser_id,
    session_id,
    application_version,
    referring_url,
    url_params,
    page_title,
    page_path,
    page_url,
    full_page_url,
  } = constructTrackingObject(config, {
    pagePath,
    referer,
    urlParams,
    host,
    fullPagePath,
    pageRealPath,
  })
  analyticsInstance.page({
    ...baseObject,
    event_action: 'page_view',
    // event-horizon v0.4.3
    // page_name ends up application_data but you can't specify it in application_data
    // if you do you will see ANALYTICS :: freedom :: page -  undefined - Page Loaded
    page_name,
    event_name,
    application_data: {
      ...application_data,
      application_version,
      url_params,
      page_title,
      page_path,
      page_url,
      full_page_url,
      referring_url,
    },
    ids: {
      ...ids,
      browser_id,
      session_id,
      auth0_user_id,
      auth0_email,
      profile_id,
    },
    profile,
    experiments,
  })
}

const useAnalyticsProcessEvents = () => {
  const {
    state: {
      analyticsInstance,
      eventQueue,
      trackingData,
      isPageLoaded,
      auth0_email,
      auth0_user_id,
      profile_id,
      config,
    },
    dispatch,
  } = useContext(AnalyticsContext)

  useEffect(() => {
    // Nothing happens if there are no events.
    if (!(eventQueue.length > 0)) return

    if (!isPageLoaded) return

    // Will reset session start time and create new session if last event was >30 min prior
    setSessionId({ dispatch })

    if (eventQueue[0].event_type === 'page') {
      trackPage(analyticsInstance, config, {
        ...trackingData,
        auth0_email,
        auth0_user_id,
        profile_id,
        ...eventQueue[0],
      })
    } else {
      trackEvent(analyticsInstance, config, {
        ...trackingData,
        auth0_email,
        auth0_user_id,
        profile_id,
        ...eventQueue[0],
      })
    }
    dispatch({ type: 'REMOVE_FIRST_EVENT_FROM_QUEUE' })

    // We don't want to see if the event queue length changed, we want to know if the contents changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventQueue, isPageLoaded])
}

const objectToQueryString = (obj) => {
  if (!obj) return ''
  return Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&')
}

const useTrackPage = () => {
  const { dispatch } = useContext(AnalyticsContext)

  const router = useRouter()
  // remove proceeding slash and ensure pathname does not include query string
  const pagePath = getPagePath(router.asPath)
  const pageRealPath = getPagePath(router.pathname)

  const urlParams = objectToQueryString(router.query)
  const { asPath: fullPagePath } = router

  useEffect(() => {
    const referer = document.referrer
    const host = location.host

    dispatch({ type: 'SET_PAGE_STATE_LOADING', payload: { isPageLoaded: false } })

    waitForTealium(() => {
      dispatch({
        type: 'SET_PAGE_STATE_LOADED',
        payload: {
          isPageLoaded: true,
          trackingData: { referer, host, pagePath, urlParams, fullPagePath },
        },
      })

      // Wait till after to trigger processing the queue once the page has loaded
      dispatch({
        type: 'ADD_EVENT_TO_QUEUE',
        payload: {
          event_type: 'page',
          referer,
          host,
          pagePath,
          urlParams,
          fullPagePath,
          pageRealPath,
        },
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullPagePath])
}
export { trackEvent, trackPage, useTrackPage, useAnalyticsProcessEvents, handleTrackAndReactEvent }
