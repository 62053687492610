import {
  useTrackPage,
  useAnalyticsProcessEvents,
  useBrowserId,
  useSessionId,
  removeProfileCookie,
} from 'utils/analytics'
import { Tealium } from 'components/Tealium'
import { useDatadog } from 'utils/datadog'
import { useBot } from 'hooks/useBot'

function Analytics({ children }) {
  useSessionId()

  useBrowserId()

  removeProfileCookie()

  useDatadog()

  useTrackPage()

  useAnalyticsProcessEvents()

  const { isBot } = useBot()

  return (
    <>
      {!isBot ? <Tealium /> : null}
      {children}
    </>
  )
}

export { Analytics }
