const ANALYTICS = {
  HUB_PROFILE_ID_COOKIE_NAME: 'achieve_profile_id',
  SESSION_ID_KEY_NAME: 'achieve_session_id',
  BROWSER_ID_KEY_NAME: 'achieve_browser_id',
  DATADOG_SITE: 'datadoghq.com',
  SESSION_TIMER_START: 'session_timer_start',
  SESSION_TIMEOUT: 1800000, //30 min in ms
}
Object.freeze(ANALYTICS)
export { ANALYTICS }
