import { createContext, useState, useEffect, useMemo } from 'react'
import useTheme from 'hooks/useTheme'
import { debounce as _debounce } from 'lodash-es'
import useIsSsr from 'hooks/useIsSsr'

const LayoutContext = createContext()

// default to iphone size - xs
const DEFAULT_SIZE = Object.freeze({
  breakpointPixelSize: 320,
  breakpointLetterSize: 'xs',
  screenWidth: 390,
  screenHeight: 844,
  calculatedClient: false,
})

const LayoutProvider = ({ children }) => {
  const [viewPortSize, setViewportSize] = useState(DEFAULT_SIZE)
  const isSsr = useIsSsr()
  const theme = useTheme()
  /** themeBreakpoints = { xs: 320, sm: 480, md: 768, lg: 1024, xl: 1280, xxl: 1440 } */
  const themeBreakpoints = theme.breakpoints.values
  /**
   * pixelValues is a list of tuples containing the breakpoint abbreviation
   * as the key and the pixel number as the value, sorted in ascending
   * order so that a match can be found where the current screen width
   * is greater than pixelValues[i][1] and less than pixelValues[i + 1][1]
   *
   * Example: [[ 'xs', 320 ], [ 'sm', 480 ], [ 'md', 768 ],
   * [ 'lg', 1024 ], [ 'xl', 1280 ], [ 'xxl', 1440 ]]
   *
   * */
  const pixelValues = useMemo(
    () => Object.entries(themeBreakpoints).sort((a, b) => a[1] - b[1]),
    [themeBreakpoints]
  )

  useEffect(() => {
    function getViewPortData() {
      let result
      if (!isSsr) {
        const { innerWidth: width, innerHeight: height } = window
        // use width to determine current breakpoint
        pixelValues.every(([key, value], i) => {
          if (width >= value && (i === pixelValues.length - 1 || width < pixelValues[i + 1][1])) {
            // break out of iteration once matching interval found
            result = {
              breakpointPixelSize: value,
              breakpointLetterSize: key,
              screenWidth: width,
              screenHeight: height,
              calculatedClient: true,
            }
            return false
          }
          // else continue iteration
          return true
        })
      }
      return result ?? DEFAULT_SIZE
    }

    const handleResize = _debounce(
      () => {
        setViewportSize(getViewPortData())
      },
      250,
      { maxWait: 250 }
    )

    window.addEventListener('resize', handleResize)
    setViewportSize(getViewPortData())

    return () => window.removeEventListener('resize', handleResize)
  }, [isSsr, pixelValues])

  return (
    <LayoutContext.Provider
      value={{
        viewPortSize,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export { LayoutProvider, LayoutContext }
