import { useRouter } from 'next/router'
import styles from './Navigation.module.scss'
import MainNavItem from './MainNavItem'
import useActiveNavItemIndex from 'hooks/useActiveNavItemIndex'
import PropTypes from 'prop-types'

/**
 * @param {{
 *  condensed: boolean|false
 *  disabledRoutes: []
 *  items: []
 *  mobileNavOpen: boolean|false
 * }} props
 */
export default function Navigation({
  condensed = false,
  mobileNavOpen = false,
  items,
  disabledRoutes = [],
  disabledElements = [],
}) {
  let [activeItemIndex] = useActiveNavItemIndex()
  const router = useRouter() || {}

  const filterSubNavsIfAllRoutesAreDisabled = (subNavs) => {
    return (
      subNavs
        .filter((subNavMain) => {
          let urls = []
          if (!subNavMain?.subNavMenus) return true
          //Gets a list of urls contained within sub menu Items
          for (let subNav of subNavMain.subNavMenus[0].menuItems) {
            if (subNav?.url) {
              urls.push(subNav.url)
            }
          }

          //Removes sub menus with no routes available
          return !(
            urls.filter((url) => {
              return !disabledRoutes.includes(url)
            }).length === 0
          )
        })
        //This mapping logic returns JSX corresponding to sub menus available
        .map((props, i) => (
          <MainNavItem
            itemIndex={i}
            key={i}
            currentPathname={router.pathname}
            {...props}
            disabledRoutes={disabledRoutes}
            disabledElements={disabledElements}
          />
        ))
    )
  }

  return (
    <nav
      className={styles['main-nav']}
      data-condensed={condensed}
      data-mobile-nav-open={mobileNavOpen}
      data-subnav-open={activeItemIndex >= 0}
    >
      <ul>{filterSubNavsIfAllRoutesAreDisabled(items)}</ul>
    </nav>
  )
}

Navigation.propTypes = {
  condensed: PropTypes.bool,
  disabledRoutes: PropTypes.array,
  disabledElements: PropTypes.object,
  mobileNavOpen: PropTypes.bool,
  items: PropTypes.array.isRequired,
}
