import { Typography as SunbeamTypography } from '@achieve/sunbeam'

const SuperScript = ({ text, variation = 'blue', ...props }) => {
  /**
   * TODO: Refactor to move colors and font styles into css.  Remove
   *       inline styles and use typography variation
   *
   * NOTE: Styled inline because containing Typography is overriding
   *       the variation passed in to this child Typography component
   */
  const colorMap = {
    white: '#FFFFFF',
    blue: '#3300FF',
    black: '#000000',
    inherit: 'inherit',
  }
  return (
    <SunbeamTypography
      style={{
        fontSize: 'max(13px, 0.5em)',
        fontWeight: 400,
        fontFamily: 'Noto Sans',
        lineHeight: 'max(13px, 0.5em)',
        color: colorMap[variation],
        verticalAlign: 'super',
        padding: 0,
      }}
      {...props}
      component="sup"
    >
      {text}
    </SunbeamTypography>
  )
}

export { SuperScript }
