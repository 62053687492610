import Script from 'next/script'
import { addTealiumScript } from 'components/Tealium/addTealiumScript'
import { useConfig } from 'providers/AnalyticsProvider'

function Tealium() {
  const { config } = useConfig()
  return (
    <Script
      id="tealium-base"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: addTealiumScript(config.NEXT_PUBLIC_TEALIUM_UTAG_URL),
      }}
    />
  )
}

export { Tealium }
