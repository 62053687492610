import { useEffect, useContext } from 'react'
import { ANALYTICS } from 'constants/analytics'
import Cookies from 'js-cookie'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { v4 as uuidv4 } from 'uuid'

const setBrowserId = ({ dispatch }) => {
  const browserId = Cookies.get(ANALYTICS.BROWSER_ID_KEY_NAME)
  if (browserId) return
  const newBrowserId = uuidv4()
  Cookies.set(ANALYTICS.BROWSER_ID_KEY_NAME, newBrowserId, {
    expires: 36525,
    sameSite: 'strict',
  })
  dispatch({
    type: 'SET_BROWSER_ID',
    id: newBrowserId,
  })
}

const useBrowserId = () => {
  const { dispatch } = useContext(AnalyticsContext)
  return useEffect(() => {
    setBrowserId({ dispatch })
  }, [dispatch])
}

export { setBrowserId, useBrowserId }
